<template>
  <div>
    <section v-show="display">
      <common-header class="header" />
      <div class="noCard"></div>
      <div class="main-box">
        <div class="about-side-bar">
          <div class="title">
            精英<br />风采
          </div>
          <div class="side-nav">
            <div class="nav-item active">
              <div>明亚名人堂</div>
              <img src="../../assets/img/aboutus/more-down.png" alt="" style="width: 0.1rem; height: 0.06rem;">
            </div>
            <div class="nav-item pl35 active2">
              <div>名人堂会员</div>
              <img src="../../assets/img/aboutus/more_active.png" alt="">
            </div>
            <div class="nav-item pl35">
              <div>名人汇</div>
              <img src="../../assets/img/aboutus/more.png" alt="">
            </div>
            <div class="nav-item">
              <div>明亚高峰会</div>
              <img src="../../assets/img/aboutus/more.png" alt="">
            </div>
            <div class="nav-item">
              <div>明亚精英</div>
              <img src="../../assets/img/aboutus/more.png" alt="">
            </div>
            <div class="nav-item">
              <div>明亚生活</div>
              <img src="../../assets/img/aboutus/more.png" alt="">
            </div>
          </div>
        </div>
        <div class="dis-culture">
          <div class="titles">
            <div class="title-name">名人堂会员</div>
            <div class="en hightlight"><span>/</span> ELITE</div>
            <div class="en">STYLE</div>
          </div>
          <img src="../../assets/img/celebrity/demo.png" alt="" style="margin-top: 0.67rem;">
          <div class="unav">
            <div class="nav">
              <div class="li active"><img src="../../assets/img/celebrity/long-active.png" style="width: 0.18rem;height: 0.15rem;">终身会员</div>
              <div class="li"><img src="../../assets/img/celebrity/shi.png" style="width: 0.17rem;height: 0.16rem;">钻石会员</div>
              <div class="li"><img src="../../assets/img/celebrity/xing.png" style="width: 0.18rem;height: 0.17rem;">金牌会员</div>
              <div class="li"><img src="../../assets/img/celebrity/xing.png" style="width: 0.18rem;height: 0.17rem;">银牌会员</div>
            </div>
            <div class="search">
              <input type="text" placeholder="请输入会员姓名">
              <img src="../../assets/img/celebrity/search.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <common-footer />
    </section>
    <loading v-show="!display" />
    <service />
  </div>
</template>

<script>
  import CommonHeader from '@/components/common/Header.vue'
  import CommonFooter from '@/components/common/Footer.vue'
  import Loading from '@/components/common/Loading.vue'
  import Service from '@/components/common/Service.vue'
  
  export default{
    components: {
      CommonHeader,
      CommonFooter,
      Loading,
      Service
    },
    data(){
      return{
        display: false
      }
    },
    mounted() {
      this.display = true;
    }
  }
</script>

<style lang="scss" scoped>
  .main-box{
    max-width: 14rem;
    margin: 0 auto 0;
    padding-top: 1.26rem;
    display: flex;
    .dis-culture{
      flex: 1;
      margin-left: 0.6rem;
      .unav{
        display: flex;
        width: 100%;
        align-items: center;
        padding-top: 0.6rem;
        .search{
          width: 3.6rem;
          height: 0.48rem;
          background: #FFFFFF;
          box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0.24rem;
          display: flex;
          align-items: center;
          overflow: hidden;
          img{
            width: 0.8rem;
            height: 0.48rem;
          }
          input{
            flex: 1;
            border: 0;
            padding: 0 0.1rem;
            padding-left: 0.3rem;
          }
          input:focus{
            border: 0;
            outline: 0
          }
          input::-webkit-input-placeholder{
            color: #CCCCCC;
            font-size: 0.16rem;
          }
          input::-moz-placeholder{
            color: #CCCCCC;
            font-size: 0.16rem;
          }
          input:-moz-placeholder{
            color: #CCCCCC;
            font-size: 0.16rem;
          }
          input:-ms-input-placeholder{
            color: #CCCCCC;
            font-size: 0.16rem;
          }
        }
        .nav{
          flex: 1;
          display: flex;
          align-items: center;
          .li{
            width: 1.5rem;
            height: 0.48rem;
            line-height: 0.48rem;
            border-radius: 0.48rem;
            background-color: #F7F7F7;
            text-align: center;
            font-size: 0.16rem;
            color: #666666;
            margin-right: 0.2rem;
            &.active{
              background-image: url(../../assets/img/celebrity/active.png);
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: 100%;
              height: 0.53rem;
              color: #FFFFFF;
            }
            img{
              vertical-align: sub;
              margin-right: 0.08rem;
            }
          }
        }
      }
      .titles {
        display: flex;
        height: 1.06rem;
        align-items: center;
        
        border-bottom: 1px solid #DCDCDC;
        .title-name {
          font-size: .3rem;
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          &::after {
            width: 100%;
            height: 0.04rem;
            display: block;
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            background-color: #FE6917;
          }
        }
        .en {
          font-size: .16rem;
          color: #DCDCDC;
          margin-left: .4rem;
          letter-spacing: .22rem;
          &.hightlight {
            color: #FE6917;
          }
        }
      }
    }
    .about-side-bar {
      width: 3rem;
      height: auto;
      
      .title {
        width: 100%;
        height: 1.1rem;
        background-image: url(../../assets/img/aboutus/nav.png);
        background-size: 100% 100%;
        font-size: 0.3rem;
        color: #ffffff;
        box-sizing: border-box;
        padding: .19rem .24rem 0;
        line-height: 1.3;
      }
      .side-nav {
        width: 100%;
        .nav-item {
          background-color: #ffffff;
          width: 100%;
          display: flex;
          height: .55rem;
          justify-content: space-between;
          align-items: center;
          font-size: .18rem;
          color: #333333;
          box-sizing: border-box;
          padding: 0 0.2rem;
          border-bottom: 1px solid #DCDCDC;
          cursor: pointer;
          &.pl35{
            padding-left: 0.35rem;
          }
          &.active2{
            color: #FF6004;
          }
          &:hover {
            background-color: #FFF8EC;
            color: #FF6004;
            border-color:#FFA829;
          }
          &.active {
            background-color: #FFF8EC;
            color: #FF6004;
            border-color: #FFA829;
            position: relative;
            &::after {
              display: block;
              content: "";
              height: 100%;
              width: 0.04rem;
              background-color: #FF6004;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        img {
          width: 0.06rem;
          height: 0.1rem;
        }
      }
    }
  }
  .noCard{
    width: 100%;
    height: 0.1rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    margin-top: -0.1rem;
  }
  .header {
    display: block!important;
  }
</style>
